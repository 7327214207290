import React from 'react';
import styled from 'styled-components';
import Hero from './Hero';
import ValueProp from '../../layout/ValueProp';
import Footer from '../../layout/Footer';
import Phone from "../../layout/Phone"
import FeatureSplitPNG from "../../../assets/feature/split.png"
import Section from "../../layout/Section"
import SectionHeader from "../../layout/SectionHeader"

const FeatureSplitScreen = styled.div`
  width: 245px;
  height: 530px;
  
  background-image: url(${FeatureSplitPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

export default function IndexScreen() {
  return (
    <>
      <Hero />
      <Section>
        <ValueProp
          orientation="left"
          media={<Phone><FeatureSplitScreen/></Phone>}
          title="Gather your group"
          desc="Create groups for roommates, friends, family, classmates, co-workers, etc. Customize permissions, set limits, and chat."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="right"
          media={<Phone><FeatureSplitScreen/></Phone>}
          title="Split Anything"
          desc="Add expenses and share the cost with others. You can specify how to split expenses in a variety of ways such as even splits, exact amounts, by percentage, by plate, or with shares."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="left"
          media={<Phone><FeatureSplitScreen/></Phone>}
          title="Easily Settle Payments"
          desc="After you add an expense the members of your group will receive a request to pay. Whoever created the expense can use their Splyt card which is instantly funded by the group for the amount specified."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="right"
          media={<Phone><FeatureSplitScreen/></Phone>}
          title="Seamless Payments"
          desc="Paying with Splyt is super easy. Use the free Splyt debit card or account numbers provided for each group for things like rent"
        />
      </Section>
      <Footer />
    </>
  );
}
