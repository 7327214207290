import React from 'react';

import HowItWorksScreen from '../components/screens/HowItWorksScreen/HowItWorksScreen';
import { GlobalStyle } from '../components/shared/global';
import PageMetadata from "../components/page-metadata"
import SiteHeader from "../components/layout/SiteHeader"

export default () => (
  <>
    <PageMetadata title="How It Works" />
    <GlobalStyle />
    <SiteHeader />
    <main>
      <HowItWorksScreen />
    </main>
  </>
);