import React from 'react';
import styled from 'styled-components';
import { background, color, layout, typography } from "../../shared/styles"
import Section from "../../layout/Section"
import ColumnLayout from "../../layout/ColumnLayout"
import HowItWorksHeroPNG from "../../../assets/how-it-works-hero.png"

const HeroTitle = styled.section`
  padding-left: ${layout.columnPaddingNormal}px;
  
  text-align: center;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    text-align: left;
    padding-right: 70px;
  }
  z-index: 2;
`;

const Title = styled.h1`
  font-weight: ${typography.weight.bold};
  font-size: 38px;
  line-height: 1.2;
  margin-top: 80px;
  margin-bottom: 16px;
`;

const Subtitle = styled.div`
  font-size: ${typography.size.p1}px;
  line-height: 1.5;
  color: ${color.textSecondary};
  margin-bottom: 1rem;
`;

const HeroGraphicBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  width: 574px;
  height: 369px;
  background-image: url(${HowItWorksHeroPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const HeroGraphic = styled.div`
  position: relative;
  z-index: 1;
  
  user-select: none;
  
  height: 369px;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    height: 369px;
  }
`;

export default function Hero() {
  return (
    <>
      <Section backgroundColor={background.bg}>
        <ColumnLayout columns="1,1">
          <HeroTitle>
            <Title>Split expenses without complex calculations and awkwardness</Title>
            <Subtitle>
              Forget stressing about who owes who or when you are going to get paid back. With Splyt, you can split expenses in real-time and avoid the awkwardness.
            </Subtitle>
          </HeroTitle>
          <HeroGraphic>
            <HeroGraphicBackground></HeroGraphicBackground>
          </HeroGraphic>
        </ColumnLayout>
      </Section>
    </>
  );
}
